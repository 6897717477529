<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Pago de Crédito - Editar</strong>

            <router-link class="btn btn-light float-right btn-sm"  :to="{ path: '/pago-credito/listar' }" append ><i class="fa fa-chevron-left"></i> Regresar</router-link>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" autocomplete="off" @submit.prevent="Validate">
              <b-row>

                <b-col lg="6">
                  <b-form-group>
                    <label>Cliente:</label>
                    <v-select disabled placeholder="Seleccione un cliente" class="w-100" :filterable="false" label="text" v-model="client" :options="clients"></v-select>
                    <small v-if="errors.id_client" class="form-text text-danger" >Seleccione un cliente</small>
                  </b-form-group>
                </b-col>
                
                <b-col md="2">
                  <b-form-group label="Nro Pago:">
                    <b-form-input readonly class="text-center" type="text" v-model="credit_payment.code"></b-form-input>
                    <small v-if="errors.payment_method"  class="form-text text-danger" >Medio de Pago</small>
                  </b-form-group>
                </b-col>


                <b-col md="2">
                  <b-form-group label="Medio de pago:">
                    <b-form-select v-model="credit_payment.payment_method" :options="payment_method"></b-form-select>
                    <small v-if="errors.payment_method"  class="form-text text-danger" >Medio de Pago</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Fecha:">
                    <b-form-input disabled type="date" class="text-center" v-model="credit_payment.date"></b-form-input>
                    <small v-if="errors.date"  class="form-text text-danger" >Ingrese un fecha</small>
                  </b-form-group>
                </b-col>

                <b-col md="7">
                  <b-form-group label="Observación:">
                    <b-form-textarea v-model="credit_payment.observation"></b-form-textarea>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label=".">
                    <b-button class="form-control" :disabeld="credit_payment.file.length == 0" @click="OpenAnnexes" type="button" variant="info">Ver Anexos</b-button>
                  </b-form-group>
                </b-col>
                
                <b-col md="3">
                  <b-form-group label="Reemplazar Anexo:">
                     <b-form-file accept="application/pdf"  v-model="file" :state="Boolean(file)" @change="onFileChange" placeholder="Selecciona un archivo" drop-placeholder="Arrastre el archivo aqui"></b-form-file>
                    <small v-if="errors.file"  class="form-text text-danger" >Seleccione un documento</small>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <div class="table-responsive">
                    <table class="table table-hover table-bordered">
                      <thead>
                        <tr>
                          <th width="7%" class="text-center">Crédito</th>
                          <th width="7%" class="text-center">Monto</th>
                          <th width="5%" class="text-center">Nro</th>
                          <th width="7%" class="text-center">Fecha</th>
                          <th width="6%" class="text-center">Saldo <br> Capital</th>
                          <th width="6%" class="text-center">Capital</th>
                          <th width="6%" class="text-center">Interes</th>
                          <th width="6%" class="text-center">Cuota</th>
                          <th width="6%" class="text-center">Amort.</th>
                          <th width="6%" class="text-center">Dias Moros.</th>
                          <th width="6%" class="text-center">Morosidad</th>
                          <th width="9%" class="text-center">Desc. <br> Interes</th>
                          <th width="9%" class="text-center">Desc. <br> Morosidad</th>
                          <th width="7%" class="text-center">Cuota Final</th>
                        </tr>
                      </thead>
                      <tbody v-for="(item, it) in credits_details" :key="it">
                        <tr>
                          <td class="text-center">{{ item.credit_code }}</td>
                          <td class="text-right">{{ item.credit_amount }}</td>
                          <td class="text-center">{{ item.credit_detail_quota_number }}</td>
                          <td class="text-center"> {{ item.credit_detail_date  }}</td>
                          <td class="text-right"> {{ item.credit_detail_balance  }}</td>
                          <td class="text-right"> {{ item.credit_detail_capital_balance  }}</td>
                          <td class="text-right"> {{ item.credit_detail_interest  }}</td>
                          <td class="text-right  bg-warning"> {{ item.credit_detail_quota  }}</td>
                          <td class="text-right  bg-info">{{ item.credit_detail_installment_amortization  }} </td>
                          <td class="text-right"> {{ item.days_delinquency  }}</td>
                          <td class="text-right">{{ item.delinquency  }} </td>
                          <td class="text-right">{{ item.discount_interest  }} </td>
                          <td class="text-right">{{ item.discount_delinquency  }} </td>
                          <td class="text-right  bg-success">{{ item.total_payment  }} </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>

                <b-col md="9">
                </b-col>
                <b-col md="3">
                  <div class="table-responsive">
                    <table  class="table table-hover table-lg mt-lg mb-0">
                      <tbody>
                        <tr>
                            <td class="align-middle text-right text-total">Subtotal:</td>
                            <td class="align-middle text-right text-total">
                              <b-form-input readonly size="sm" type="number" step="any" class="text-right" v-model="credit_payment.subtotal"></b-form-input>
                            </td>
                        </tr>
                        <tr>
                            <td class="align-middle text-right text-total">Morosidad:</td>
                            <td class="align-middle text-right text-total">
                              <b-form-input readonly size="sm" type="number" step="any" class="text-right" v-model="credit_payment.delinquency"></b-form-input>
                            </td>
                        </tr>
                        <tr>
                            <td class="align-middle text-right text-total">Descuento:</td>
                            <td class="align-middle text-right text-total">
                              <b-form-input readonly size="sm" type="number" step="any" class="text-right" v-model="credit_payment.discount"></b-form-input>
                            </td>
                        </tr>
                        <tr>
                            <td class="align-middle text-right text-total">Total:</td>
                            <td class="align-middle text-right text-total">
                              <b-form-input readonly size="sm" type="number" step="any" class="text-right" v-model="credit_payment.total"></b-form-input>
                            </td>
                        </tr>
                        <tr v-if="errors.total">
                          <td colspan="2" class="text-center">
                            <small  class="form-text text-danger" >Seleccione 1 o mas cuotas a pagar</small>    
                          </td>
                        </tr>

                        
                      </tbody>
                    </table>
                  </div>
                </b-col>


               

                <b-col md="4"></b-col>
                <b-col md="2">
                  <b-button class="form-control" @click="OpenVoucher" type="button" variant="warning">Imprimir Recibo</b-button>
                </b-col>
                
                <b-col md="2">
                  <b-button type="submit" class="form-control" variant="primary" ><i class="fas fa-save"></i> Guardar (F4)</b-button>
                </b-col>

              
                
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>


    <ModalClients />
    <LoadingComponent :is-visible="isLoading"/>
    <Keypress key-event="keyup" :key-code="115" @success="Validate" />
  </div>
</template>

<script>

import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";


const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");

import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
import DataCollection from "@/assets/js/DataCollection";// components
// components
import ModalClients from './../components/ModalClient'
import LoadingComponent from './../pages/Loading'
export default {
  name: "CreditPaymentEdit",
  props: ["id_credit_payment"],
  components:{
      vSelect,
      ModalClients,
      Keypress: () => import('vue-keypress'),
      LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      module: 'CreditPayment',
      role: 3,
      credit_payment: {
          id_credit_payment:'',
          id_client:'',
          id_user:'',
          id_branch_office:'',
          id_credit:'',
          code:'',
          payment_method:'008',
          date:moment(new Date()).local().format("YYYY-MM-DD"),
          description:'',
          file:'',
          subtotal:'0.00',
          delinquency:'0.00',
          discount:'0.00',
          total:'0.00',
          state:1,
          created_at:'',
          updated_at:'',
          file_new:'',
          id_product:'',
          id_product_type:'',
      },
      file:null,
      modules:[],
      // payment_method:[
      //   {value:'008', text:'EFECTIVO'},
      //   {value:'007', text:'CHEQUES'},
      //   {value:'001', text:'DEPÓSITO EN CUENTA'},
      //   {value:'003', text:'TRANSFERENCIA DE FONDOS'},
      // ],
      client: null,
      clients: [],
      state:[
        {value:'1',text:'Activo'},
        {value:'0',text:'Inactivo'},
      ],
      credits_details:[],
      errors: {
        id_client: false,
        payment_method: false,
        date: false,
        total: false,
      },
      validate: false,
    };
  },
  mounted() {
    this.ViewCreditPayment();
  },
  methods: {
    ViewCreditPayment,

    EditCreditPayment,
    Validate,

    onFileChange,
    OpenAnnexes,

    OpenVoucher,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
    payment_method: function () {
      return DataCollection.MethodPayment();
    }
  },
};

function ViewCreditPayment() {
  let me = this;
  let id_credit_payment = je.decrypt(this.id_credit_payment);
  let url = me.url_base + "credit-payment/view/"+id_credit_payment;
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module, role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.credit_payment.id_credit_payment = response.data.result.id_credit_payment;
        me.credit_payment.id_client = response.data.result.id_client;
        me.credit_payment.id_user = response.data.result.id_user;
        me.credit_payment.id_branch_office = response.data.result.id_branch_office;
        me.credit_payment.id_credit = response.data.result.id_credit;
        me.credit_payment.code = response.data.result.code;
        me.credit_payment.payment_method = response.data.result.payment_method;
        me.credit_payment.date = response.data.result.date;
        me.credit_payment.observation = response.data.result.observation;
        me.credit_payment.file = response.data.result.file;
        me.credit_payment.subtotal = response.data.result.subtotal;
        me.credit_payment.delinquency = response.data.result.delinquency;
        me.credit_payment.discount = response.data.result.discount;
        me.credit_payment.total = response.data.result.total;
        me.credit_payment.state = response.data.result.state;
        me.credit_payment.id_product = response.data.result.id_product;
        me.credit_payment.id_product_type = response.data.result.id_product_type;
        me.client = { value: response.data.result.id_client, text: response.data.result.client_name }
        me.credits_details = response.data.result.credits_details;

     
        
      }
    });
}


function EditCreditPayment() {
  let me = this;
  let url = me.url_base + "credit-payment/edit";
  me.isLoading = true;
  let data = new FormData();
  data.append("id_credit_payment", this.credit_payment.id_credit_payment);
  data.append("payment_method", this.credit_payment.payment_method);
  data.append("observation", this.credit_payment.observation);
  data.append("file", this.credit_payment.file_new);
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.credit_payment.file = response.data.result.file;
        me.file = null;
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function Validate() {
  this.errors.id_client = this.client == null ? true : false;
  this.errors.payment_method = this.credit_payment.payment_method.length == 0 ? true : false;
  this.errors.date = this.credit_payment.date.length == 0 ? true : false;
  this.errors.code = this.credit_payment.code.length == 0 ? true : false;
  this.errors.total = this.credit_payment.total.length == 0 || parseFloat(this.credit_payment.total) <= 0  ? true : false;

  if (this.errors.id_client) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.payment_method) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.date) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.code) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.total) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }

  let me = this;
  Swal.fire({
    title: "Esta seguro de modificar el pago de crédito?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.EditCreditPayment();
    }
  });
}

function onFileChange(e) {
  this.credit_payment.file_new = e.target.files[0];
}

function OpenAnnexes() {
  let me = this;
  let url = me.url_base + this.credit_payment.file;
  window.open(url,'_blank');
}

function OpenVoucher() {
  let me = this;

  let url1 = me.url_base + "credit-payment-biil-of-sale/"+ this.credit_payment.id_credit_payment;
  let url2 = me.url_base + "credit-payment-sales-of-receip/"+ this.credit_payment.id_credit_payment;
  

  let url = me.url_base + "credit-payment/get-information-voucher/"+this.credit_payment.id_credit_payment;
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        if (parseFloat(response.data.result.bill_sale) > 0) {
          var ventana1 = window.open(url1, '_blank');
        }

        if (parseFloat(response.data.result.sales_receipt) > 0) {
          setTimeout(function(){
              var ventana2 = window.open(url2, '_blank');
          }, 250);
        }
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });

}

</script>
